import { User } from "../../interfaces";
import { Commit } from "vuex";

// initial state
const state = {
	user: null,
	twoFactorProviders: []
};

// getters
const getters = {
	user: (state: { user: User }) => {
		return state.user;
	},
	twoFactorProviders: (state: { twoFactorProviders }) => {
		return state.twoFactorProviders;
	},
};

// actions
const actions = {
	async setUser({ commit }: { commit: Commit }, user: User) {
		commit("setUser", user);
	},
	async setTwoFactorProviders({ commit }: { commit: Commit }, twoFactorProviders) {
		commit("seProviders", twoFactorProviders);
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
};

// mutations
const mutations = {
	setUser(state: any, user: User) {
		state.user = user;
	},
	seProviders(state: any, twoFactorProviders: any) {
		state.twoFactorProviders = twoFactorProviders;
	},
	reset(state: any) {
		state.user = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
