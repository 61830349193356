import { Commit } from "vuex";
import ProfileService from "@/services/ProfileService";
import { Profile } from "@/classes/user/Profile";

// Initial state
const state = {
    profileData: null as Profile | null,
};

// Getters
const getters = {
    getProfileData: (state: { profileData: Profile | null }) => state.profileData,
};

// Actions
const actions = {
    async getProfileData({ commit }: { commit: Commit }) {
        const profile = await ProfileService.getProfile();
        commit("setUserProfile", profile);
    },

    async reset({ commit }: { commit: Commit }) {
        commit("resetProfile");
    },
};

// Mutations
const mutations = {
    setUserProfile(state: any, profile: Profile) {
        state.profileData = profile;
    },
    reset(state: any) {
        state.profileData = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
