import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import ExceptionService from "./ExceptionService";
import { TprofileFile } from "@/classes/files/File";

class FileService {
    private apiUrl = computed(() => store.getters["session/apiUrl"]);

    async uploadFile(file: File, director?: string): Promise<TprofileFile | undefined> {
        let url = `${this.apiUrl.value}/api/files`;
        if (director) {
            url += `?directory=${director}`;
        }
        const header = await AuthHeader();
        const requestOptions = {
            headers: header
        };
        requestOptions.headers["Content-Type"] = "multipart/form-data";
        const formData = new FormData();
        formData.append("file", file);
        return await axios.post(url, formData, requestOptions)
            .then(async (response) => {
                return response.data as TprofileFile;
            })
            .catch(async (error) => {
                await ExceptionService.LogException(error);
                return null;
            });
    }

    async deleteFile(fileId: string) {
        const header = await AuthHeader();
        const requestOptions = {
            headers: header
        };
        return await axios.delete(`${this.apiUrl.value}/api/files/${fileId}`, requestOptions)
            .catch(async (error) => {
                await ExceptionService.LogException(error);
                return null;
            });
    }
}

export default new FileService();