import { useStore } from "vuex";
import { loadingController } from "@ionic/core";
import PushNotificationService from "@/services/PushNotificationService";
import EnquiryService from "@/services/EnquiryService";
import CountryService from "@/services/CountryService";
import ExceptionService from "@/services/ExceptionService";
import { App } from "@capacitor/app";

export default () => {
    const store = useStore();

    const setVersion = async () => {
        try {
            const info = await App.getInfo();
            const version = `${info.build}-${info.version}`;
            await store.dispatch("session/setVersion", version);
        } catch (e) {
            await ExceptionService.LogException(e);
        }
    };

    const loadUserDataAfterLogin = async () => {
        // const loading = await loadingController.create({
        //     cssClass: "my-custom-class",
        // });

        //await loading.present();

        await setVersion();

        //loading.message = "Login success";
        await PushNotificationService.initPush();

        //loading.message = "Loading enquiries";
        await EnquiryService.setEnquiryAfterLogin();

        //loading.message = "Loading quotes";
        await store.dispatch("quotes/getAllQuotes");

        //loading.message = "Loading bookings";
        await store.dispatch("bookings/getAllBookings");

        //loading.message = "Loading promotions";
        await store.dispatch("promotions/getPromotions");

        //loading.message = "Loading currencies";
        await store.dispatch("currencies/getAllCurrencies");

        await CountryService.setCountriesAfterLogin();
        //await loading.dismiss();
    };

    return {
        loadUserDataAfterLogin
    };
}
