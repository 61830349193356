<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar slot="bottom" color="medium">
                <ion-tab-button v-for="(tab, index) in tabs" :key="index" :tab="tab.title" :href="tab.url" :class="tab.class" layout="icon-top">
                    <div class="user-profile-icon-container" v-if="tab.title === 'Profile'">
                        <img v-if="currentProfile?.profilePicture" :src="profileUrl" alt="" class="profile-icon" />
                    </div>
                    <template v-if="tab.title != 'Profile'">
                        <custom-icon :name="tab.customIconLight" class="tab-icon" v-if="currentTheme == 'light'"> </custom-icon>
                        <custom-icon :name="tab.customIconDark" class="tab-icon" v-else> </custom-icon>
                    </template>
                    <ion-label v-html="tab.title"></ion-label>
                    <ion-badge class="count-badge" v-if="tab.badgeCount > 0">{{ tab.badgeCount }}</ion-badge>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
        <ion-fab vertical="bottom" horizontal="center">
            <ion-fab-button :key="2" :href="tabs[2].url">
                <div class="fab-icon-mask"></div>
                <custom-icon :name="tabs[2].customIconLight"></custom-icon>
            </ion-fab-button>
        </ion-fab>
    </ion-page>
</template>

<script lang="ts">
    import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonBadge, IonRouterOutlet, IonFabButton, IonFab, loadingController, alertController, RefresherCustomEvent, onIonViewDidEnter } from "@ionic/vue";
    import {
        chatbubblesOutline,
        callOutline,
    } from "ionicons/icons";
    import { computed, ref, Ref, watch, onMounted, onUnmounted  } from "vue";
    import { useRouter } from "vue-router";
    import { useStore } from "vuex";
    import CustomIcon from "@/components/CustomIcon.vue";
    import ProfileService from "@/services/ProfileService";
    import { Profile } from "@/classes/user/Profile";

    export default {
        name: "NavigationTabs",
        components: { IonBadge, IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet, IonFabButton, IonFab, CustomIcon },
        setup() {
            const router = useRouter();
            const tabs = ref();
            const store = useStore();
            const apiUrl = computed(() => store.getters["session/apiUrl"]);
            const currentRouteName = computed(() => router.currentRoute.value.name);
            const newMessageCount = computed(() => store.state.enquiries.newMessageCount);
            const newBookingCount = computed(() => store.state.bookings.newBookingCount);
            const newQuoteCount = computed(() => store.state.quotes.newQuoteCount);
            const currentProfile: Ref<Profile> = computed(() => store.state.profile.profileData || {});
            const profileUrl: Ref<string> = computed(() => currentProfile.value.profilePicture.startsWith('http')
                ? currentProfile.value.profilePicture
                : `${apiUrl.value}${currentProfile.value.profilePicture}`);

            const setupTabs = () => {
                tabs.value = [];

                tabs.value.push({
                    title: "Home",
                    url: router.resolve({ name: "mainmenu" }).href,
                    customIconLight: "icon-home-light",
                    customIconDark: "icon-home-dark",
                    badgeCount: 0,
                    class: "tab-home",
                });
                tabs.value.push({
                    title: "Messages",
                    url: router.resolve({ name: "list-messages" }).href,
                    customIconLight: "icon-messages-light",
                    customIconDark: "icon-messages-dark",
                    badgeCount: newMessageCount.value,
                    class: "tab-messages",
                });
                tabs.value.push({
                    title: "My Trips",
                    url: router.resolve({ name: "my-trips" }).href,
                    customIconLight: "transparent-icon-light",
                    customIconDark: "transparent-icon-dark",
                    badgeCount: newQuoteCount.value,
                    class: "tab-trips",
                });
                tabs.value.push({
                    title: "Offers",
                    url: router.resolve({ name: "offers-and-promotions" }).href,
                    customIconLight: "icon-promotions-light",
                    customIconDark: "icon-promotions-dark",
                    badgeCount: 0,
                    class: "tab-offers",
                });

                tabs.value.push({
                    title: "Profile",
                    url: "/tabs/profile",
                    customIconLight: "profile-icon-light",
                    customIconDark: "profile-icon-dark",
                    badgeCount: 0,
                    class: "tab-profile",
                });
            };

            const loadProfile = async () => {
                await store.dispatch("profile/getProfileData");
            };

            const doRefresh = async (event: RefresherCustomEvent) => {
                await loadProfile();
                event.target.complete();
            };

            onIonViewDidEnter(async () => {
                if (router.currentRoute.value.name != "login") {
                    await await loadProfile();
                }
            });

            const currentTheme = ref('light');

            const checkTheme = () => {
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
                currentTheme.value = prefersDark ? 'dark' : 'light';
            };

            const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const handleThemeChange = (e) => {
                currentTheme.value = e.matches ? 'dark' : 'light';
            };

            onMounted(() => {
                checkTheme();
                themeMediaQuery.addEventListener('change', handleThemeChange);
            });

            onUnmounted(() => {
                themeMediaQuery.removeEventListener('change', handleThemeChange);
            });

            watch(currentRouteName, async () => {
                setupTabs();
            });
            watch(newMessageCount, async () => {
                setupTabs();
            });
            watch(newBookingCount, async () => {
                setupTabs();
            });
            watch(newQuoteCount, async () => {
                setupTabs();
            });

            setupTabs();

            return {
                tabs,
                chatbubblesOutline,
                callOutline,
                loadProfile,
                doRefresh,
                currentProfile,
                profileUrl,
                currentTheme,
            };
        },
    };
</script>