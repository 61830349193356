import PromotionService from "@/services/PromotionService";
import { Commit } from "vuex";
import { Promotion } from "@/classes/promotions/Promotion";

// initial state
const state = {
    promotions: [],
    lastUpdated: null,
};

// getters
const getters = {
    allPromotions: (state: { promotions: Promotion[] }) => {
        return state.promotions;
    },
    lastUpdated: (state: { lastUpdated: Date | null }) => {
        return state.lastUpdated;
    },
};

// actions
const actions = {
    async getPromotions({ commit, state }: { commit: Commit; state: any }) {
        console.log(state.lastUpdated);
        const now = new Date();
        const lastUpdated = state.lastUpdated ? new Date(state.lastUpdated) : null;

        if (lastUpdated instanceof Date && !isNaN(lastUpdated.getTime())) {
            if ((now.getTime() - lastUpdated.getTime()) < 60 * 60 * 1000) {
                return;
            }
        }
        const promotions = await PromotionService.getPromotions();
        console.log(promotions);
        commit("setPromotions", promotions);
        commit("setLastUpdated", now);
    },
    async refreshPromotions({ commit }: { commit: Commit }) {
        const promotions = await PromotionService.getPromotions();
        commit("setPromotions", promotions);
        commit("setLastUpdated", new Date());
    },
};

// mutations
const mutations = {
    setPromotions(state: any, promotions: Promotion[]) {
        state.promotions = promotions;
    },
    setLastUpdated(state: any, lastUpdated: Date) {
        state.lastUpdated = lastUpdated;
    },
    reset(state: any) {
        state.promotions = [];
        state.lastUpdated = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};