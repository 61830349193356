export class Booking {
	id: string;
	reference: string;
	departureDate: string;
	passengers: [] = [];
	returnDate: string;
	totalPassengerCount: string;
	totalCost: string;
	bannerUrl: string;
	friendlyName: string;
	tileUrl: string;
	enquiryId: string;
	currencyCode: string;
}
