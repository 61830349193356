import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import ExceptionService from "./ExceptionService";
import { Promotion } from "@/classes/promotions/Promotion";

class PromotionService {
    private apiUrl = computed(() => store.getters["session/apiUrl"]);

    async getPromotions(): Promise<Promotion[] | undefined> {
        const header = await AuthHeader();
        const requestOptions = {
            headers: header,
            params: {
                pageResults: false,
                status: 2, // only get item has live status
                activeDate: new Date().toISOString(), // and active date is valid
            },
        };
        return await axios
            .get(`${this.apiUrl.value}/api/promotions`, requestOptions)
            .then(async (response) => {
                console.log("Promotions", response.data.promotions);
                return response.data.promotions as Promotion[];
            })
            .catch(async (error) => {
                await ExceptionService.LogException(error);
                return [];
            });
    }
}

export default new PromotionService();