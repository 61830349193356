import { Commit } from "vuex";
import { Journal } from "@/classes/bookings/Journal";
import BookingService from "@/services/BookingService";

// Initial state
const state = {
    currentJournal: null as Journal | null,
};

// Getters
const getters = {
    getCurrentJournal: (state: { currentJournal: Journal | null }) => state.currentJournal,
};

// Actions
const actions = {

    async setCurrentJournal({ commit }: { commit: Commit }, journal: Journal) {
        commit("setCurrentJournal", journal);
    },

    async reset({ commit }: { commit: Commit }) {
        commit("reset");
    },
};

// Mutations
const mutations = {

    setCurrentJournal(state: any, journal: Journal) {
        state.currentJournal = journal;
    },

    reset(state: any) {
        state.currentJournal = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
