import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import ExceptionService from "./ExceptionService";

export interface Country {
    countryCode: string;
    name: string;
    id: string;
}

class CountryService {
    private apiUrl = computed(() => store.getters["session/apiUrl"]);
    private defaultDestinationProviderId = "b3f2b9e6-07f5-47d0-a9e0-8921b656dbb7";

    async getCountries(destinationProviderId: string = this.defaultDestinationProviderId): Promise<Country[] | null> {
        const header = await AuthHeader();
        const requestOptions = {
            headers: header,
        };
        try {
            const storedCountries = store.getters["countries/all"];
            if (storedCountries && storedCountries.length > 0) {
                return storedCountries;
            }

            const response = await axios.get(`${this.apiUrl.value}/api/content/countries/${destinationProviderId}`, requestOptions);
            const countries = response.data.map((country: any) => ({
                countryCode: country.countryCode,
                name: country.name,
                id: country.id,
            }));
            
            store.commit("countries/setAll", countries);
            
            return countries;
        } catch (error) {
            console.error("Failed to fetch countries:", error);
            return null;
        }
    }

    async setCountriesAfterLogin(): Promise<void> {
        try {
            const countries = await this.getCountries();
            if (countries) {
                await store.dispatch("countries/setAllCountries", countries);
                console.log("Countries have been set after login.");
            }
        } catch (error) {
            ExceptionService.LogException(error);
        }
    }
}

export default new CountryService();
