import store from "@/store";
import { computed, ref } from "vue";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import EventService from "./EventService";
import QuoteService from "./QuoteService";
import EnquiryService from "./EnquiryService";
class HubService {
	public connection = null;
	private connectionCount = 0;
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	private enquiryId = computed(() => store.getters["enquiries/currentEnquiryId"]);
	public currentStatus = ref(this.connection == null ? "Not Connected" : this.connection.state);

	async initialiseConnection() {
		if (this.connectionCount > 10) {
			await EventService.LogEvent("hub_service", { details: `Tried ${this.connectionCount} times to connect, aborting.` });
			return;
		}
		this.connectionCount++;
		await EventService.LogEvent("hub_service", { details: "Initialising Hub Connection" });
		this.connection = new HubConnectionBuilder().withUrl(`${this.apiUrl.value}/hubs/enquiries`, { skipNegotiation: true, transport: HttpTransportType.WebSockets }).withAutomaticReconnect().build();

		this.connectionCount = 0;
		await this.connection
			.start()
			.then(async () => {
				this.connectionCount = 0;
				await EventService.LogEvent("hub_service", { details: "Hub Connection Connected!" });
				this.setupNotificationHooks();
			})
			.catch(async (err) => {
				await EventService.LogEvent("hub_service", { details: "Error while starting connection: " + err });
				setTimeout(async () => await this.initialiseConnection(), 5000);
			});

		this.connection.onreconnecting(async (error) => {
			await EventService.LogEvent("hub_service", { details: `Hub reconnecting: State: ${this.connection.state}`, error: error });
		});
		this.connection.onreconnected(async (connectionId) => {
			this.connectionCount = 0;
			await EventService.LogEvent("hub_service", { details: `Hub reconnected: ${this.connection.state}`, connectionId: connectionId });
		});

		this.connection.onclose(async () => {
			await EventService.LogEvent("hub_service", { details: "Hub closed" });
			setTimeout(async () => {
				await this.initialiseConnection();
			}, 10000);
		});
	}

	async closeConnection() {
		await EventService.LogEvent("hub_service", { details: "Closing connection" });
		if (this.connection != null) {
			this.connectionCount = 0;
			this.connection.stop();
		}
	}

	setupNotificationHooks() {
		// Documents
		this.connection.on("NewDocument", this.refreshDocuments);
		this.connection.on("DocumentDeleted", this.refreshDocuments);
		// Messages
		this.connection.on("NewMessage", this.newMessage);
		// Bookings
		this.connection.on("NewBooking", this.newBooking);
		this.connection.on("BookingUpdated", this.bookingUpdated);
		// Quotes
		this.connection.on("NewQuote", this.newQuote);
		this.connection.on("QuoteUpdated", this.quoteUpdated);
		// Enquiries
		this.connection.on("NewEnquiry", this.newEnquiry);
	}

	async checkConnectionState() {
		await EventService.LogEvent("hub_service", { details: "Checking connection state" });
		if (this.connection == null) {
			return false;
		} else if (this.connection != null && this.connection.state != "Connected") {
			await EventService.LogEvent("hub_service", { details: `Connection state: ${this.connection.state}` });
			return false;
		} else if (this.connection != null && this.connection.state == "Connected") {
			await EventService.LogEvent("hub_service", { details: `Connection state: ${this.connection.state}` });
			return true;
		}
	}

	async leaveEnquiryNotificationGroup(enquiryId: string) {
		this.connection.invoke("LeaveEnquiryGroup", enquiryId).catch(async (err) => {
			await EventService.LogEvent("hub_service", { details: `Error leaving enquiry group`, error: err });
		});
	}

	async reconnect() {
		await this.joinEnquiryNotificationGroup(this.enquiryId.value);
	}

	async joinEnquiryNotificationGroup(enquiryId: string) {
		if (this.connection == null) {
			await this.initialiseConnection();
		}

		this.connection.invoke("JoinEnquiryGroup", enquiryId).catch(async (err) => {
			await EventService.LogEvent("hub_service", { details: `Error joining enquiry group`, error: err });
		});
	}

	async refreshDocuments(bookingId: string) {
		await EventService.LogEvent("hub_service", { details: "NewDocument event", bookingId: bookingId });
		await store.dispatch("enquiries/getAllDocuments");
	}
	async newMessage(message: string, enquiryId: string) {
		await EventService.LogEvent("hub_service", { details: `NewMessage event`, message: message });
		await store.dispatch("enquiries/getAllMessages");

		if (window.location.href.indexOf("tabs/messages") == -1) {
			if (enquiryId) {
				await store.dispatch("enquiries/setEnquiryHasNewMessage", enquiryId);
			}
			await store.dispatch("enquiries/increaseMessageCount");
		}
	}

	async newBooking() {
		await EventService.LogEvent("hub_service", { details: `NewBooking event` });
		await store.dispatch("bookings/getAllBookings");
		await store.dispatch("bookings/increaseBookingCount");
	}
	async bookingUpdated() {
		await EventService.LogEvent("hub_service", { details: `NewBooking event` });
		await store.dispatch("bookings/getAllBookings");
		await store.dispatch("bookings/increaseBookingCount");
	}

	async newQuote() {
		await EventService.LogEvent("hub_service", { details: `newQuote event` });
		await QuoteService.getQuotes();
	}
	async quoteUpdated() {
		await EventService.LogEvent("hub_service", { details: `quoteUpdated event` });
		await QuoteService.getQuotes();
	}
	async newEnquiry() {
		await EventService.LogEvent("hub_service", { details: `newEnquiry event` });
		await EnquiryService.setEnquiryAfterLogin();
	}
}

export default new HubService();
