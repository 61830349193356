import CurrencyService from "@/services/CurrencyService";
import { Commit } from "vuex";
import { Currency } from "@/classes/currencies";

// initial state
const state = {
    allCurrencies: [],
};

// getters
const getters = {
    allCurrencies: (state: { allCurrencies: Currency[] }) => {
        return state.allCurrencies;
    }
};

// actions
const actions = {
    async getAllCurrencies({ commit, state }: { commit: Commit; state: any }) {
        const currencies = await CurrencyService.getAvailableCurrenciesForExchange();
        console.log(currencies);
        commit("setCurrencies", currencies);
    }
};

// mutations
const mutations = {
    setCurrencies(state: any, currencies: Currency[]) {
        state.allCurrencies = currencies;
    },
    reset(state: any) {
        state.allCurrencies = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};