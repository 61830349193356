import axios, { AxiosResponse } from "axios";

import { AuthHeader } from "./AuthHeader";
import { Booking } from "@/classes/bookings/Booking";
import store from "@/store";
import { computed } from "vue";
import { ItineraryItem } from "@/classes/bookings/ItineraryItem";
import { LegOrderUpdate } from "@/classes/bookings/LegOrderUpdate";
import { Journal } from "@/classes/bookings/Journal";

class BookingService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	async getBookings(): Promise<Booking[] | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.get(`${this.apiUrl.value}/api/bookings/member`, requestOptions)
			.then(async (response) => {
				return response.data as Booking[];
			})
			.catch(() => {
				return [];
			});
	}

	async getBookingById(bookingId: string): Promise<Booking | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.get(`${this.apiUrl.value}/api/bookings/${bookingId}/detailed`, requestOptions)
			.then(async (response) => {
				return response.data as Booking;
			})
			.catch(() => {
				return null;
			});
	}

	async addToItinerary(itineraryItem: ItineraryItem): Promise<number | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.put(`${this.apiUrl.value}/api/bookings/${itineraryItem.bookingId}/customer/extras`, itineraryItem, requestOptions)
			.then(async (response) => {
				return parseInt(response.data);
			})
			.catch(() => {
				return null;
			});
	}

	async getExtra(bookingId: string, extraId: string): Promise<ItineraryItem | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.get(`${this.apiUrl.value}/api/bookings/${bookingId}/customer/extras/${extraId}`, requestOptions)
			.then(async (response) => {
				return response.data as ItineraryItem;
			})
			.catch(() => {
				return null;
			});
	}

	async updateExtra(bookingId: string, extra: ItineraryItem): Promise<number | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.patch(`${this.apiUrl.value}/api/bookings/${bookingId}/customer/extras/${extra.id}`, extra, requestOptions)
			.then(async (response) => {
				return parseInt(response.data);
			})
			.catch(() => {
				return null;
			});
	}

	async deleteExtra(bookingId: string, extraId: string): Promise<boolean> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.delete(`${this.apiUrl.value}/api/bookings/${bookingId}/customer/extras/${extraId}`, requestOptions)
			.then(async () => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}

	async updateItineraryOrder(bookingId: string, order: LegOrderUpdate[]): Promise<AxiosResponse<any>> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return axios.post(`${this.apiUrl.value}/api/bookings/${bookingId}/legs/update-order`, order, requestOptions);
	}

	async getBookingJournal(bookingId: string): Promise<Journal[] | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.get(`${this.apiUrl.value}/api/bookings/${bookingId}/notes`, requestOptions)
			.then(async (response) => {
				return response.data as Journal[];
			})
			.catch(() => {
				return null;
			});
	}

	async getBookingJournalById(bookingId: string, journalId: number): Promise<Journal | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.get(`${this.apiUrl.value}/api/bookings/${bookingId}/notes/${journalId}`, requestOptions)
			.then(async (response) => {
				return response.data as Journal;
			})
			.catch(() => {
				return null;
			});
	}

	async addBookingJournal(bookingId: string, journal: Journal): Promise<AxiosResponse<any>> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.put(`${this.apiUrl.value}/api/bookings/${bookingId}/notes`, journal, requestOptions)
			.then(async (response) => {
				return response.data;
			})
			.catch(() => {
				return null;
			});
	}

	async deleteBookingJournal(bookingId: string, journalId: number): Promise<AxiosResponse<any>> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios
			.delete(`${this.apiUrl.value}/api/bookings/${bookingId}/notes/${journalId}`, requestOptions)
			.then(async (response) => {
				return response.data;
			})
			.catch(() => {
				return null;
			});
	}
}

export default new BookingService();
