import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import ExceptionService from "./ExceptionService";
import { Currency, CurrencyRate } from "@/classes/currencies";

class CurrencyService {
    private apiUrl = computed(() => store.getters["session/apiUrl"]);

    async getAvailableCurrenciesForExchange(): Promise<Currency[] | undefined> {
        const header = await AuthHeader();
        const requestOptions = {
            headers: header
        };
        return await axios.get(`${this.apiUrl.value}/api/currencies/available`, requestOptions)
            .then(async (response) => {
                return response.data as Currency[];
            })
            .catch(async (error) => {
                await ExceptionService.LogException(error);
                return [];
            });
    }

    async getLatestRate(fromCurrencyCode: string, toCurrencyCode: string): Promise<CurrencyRate | undefined> {
        const header = await AuthHeader();
        const requestOptions = {
            headers: header
        };
        return await axios.get(`${this.apiUrl.value}/api/currencies/rate/${fromCurrencyCode}/${toCurrencyCode}`, requestOptions)
            .then(async (response) => {
                return response.data as CurrencyRate;
            })
            .catch(async (error) => {
                await ExceptionService.LogException(error);
                return null;
            });
    }
}

export default new CurrencyService();